<template>
    <section class="zonas-comunes-index ml-4 mt-3">
        <tabs :tabs="arrayTabs" class="mx-0" />
        <router-view class="mt-4" />
    </section>
</template>
<script>
export default {
    data(){
        return{
        }
    },
    computed:{
        arrayTabs(){
            if (this.$usuario.tipo == 4 || this.$usuario.tipo == 6){
                return [
                    {
                        titulo:'Historial',
                        ruta:"zonas.historial",
                    },
                    {
                        titulo: 'Verificar pago',
                        ruta: 'zonas.verificar-pago'
                    }
                ]
            }else{
                return  [
                    {
                        titulo:'Reservas pendientes',
                        ruta:"zonas.reservas",
                    },
                    {
                        titulo:'Pagos pendientes',
                        ruta:"zonas.pago-pendiente",
                    },
                    {
                        titulo: 'Verificar pago',
                        ruta: 'zonas.verificar-pago'
                    },
                    {
                        titulo:'Historial',
                        ruta:"zonas.historial",
                    },
                    {
                        titulo:'Zonas comunes',
                        ruta:"zonas.listado",
                    },
                ]
            }
        }
    }
}
</script>